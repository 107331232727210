"use client"

import React, {ChangeEvent, useEffect, useState} from 'react';
import {sendSMS} from '../../../lib/network/api';
import {IBannerNewComponentProps, ISmsSentModalProps} from '../../../lib/types/components';
import {ICountrySelectComponentProps} from "../../../lib/types/components.us";
import {countryCodes} from "../../../lib/utils/constants/CountryCode";
import {
    GET_APP_FACEBOOK_URL,
    GET_APP_FB_URL,
    numberValidationMapping,
    SENDING_MSG,
    USER_COUNTRY_CODE,
    UTM_PLAYSTORE
} from '../../../lib/utils/constants/GlobalConstants';
import {
    getCookie,
    getCountryFromLocale,
    getOnlineStatus, getQueryStrings,
} from '../../../lib/utils/constants/PagesConstants';
import CountrySelectComponent from "../CountrySelect/CountrySelectComponent";
import SmsSentModal from '../SmsSentModal/SmsSentModal';
import CustomCarouselComponent from "../CustomCarousel/CustomCarouselComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import useIsMobile from "../../../lib/customHooks/isMobile";
import styles from "./BannerNewComponentV2.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import { useDownloadContext } from '../../../lib/context/CommonContext';
import HTMLReactParser from 'html-react-parser';

const MAX_MOBILE_NUMBER_LENGTH = 15;
const MIN_MOBILE_NUMBER_LENGTH = 7;

type BannerImageData = {
    bannerImageFg: string,
    bannerBgImage: string,
    bannerHeadlineImage: string
}

const BannerNewComponentV2 = (props: IBannerNewComponentProps) => {
    const {
        bannerHomeData,
        smsSentModalData,
        smsVariablesTemplate,
        mobileNumber,
        disableAppDownload = false,
        hideMobileNumberSection = false,
        pageName,
        consent,
        bannerMobilePlaystore
    } = props;
    const {setIsNetworkToastOpen} = useDownloadContext();
    const {isMobileDevice} = useIsMobile()
    const isDwarfBanner: boolean = pageName === GET_APP_FACEBOOK_URL || pageName === GET_APP_FB_URL;
    const [isUtmPlayStore, setIsUtmPlayStore] = useState<boolean>(false);

    const [mobileBannerAssets, setMobileBannerAsstes] = useState<BannerImageData | null>(null);

    const [desktopBannerAssets, setDesktopBannerAssets] = useState<BannerImageData | null>(null);

    useEffect(() => {
        const utmBanner = getQueryStrings().utm_banner;
        const utmBgColor = getQueryStrings().utm_banner_color;

        if (utmBanner) {
            setMobileBannerAsstes({
                bannerImageFg: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-fg.webp",
                bannerBgImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-bg.webp",
                bannerHeadlineImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-text.webp"
            })
            setDesktopBannerAssets({
                bannerImageFg: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-fg.webp",
                bannerBgImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-bg.webp",
                bannerHeadlineImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-text.webp"
            })
        }

        if (utmBgColor) {
            setMobileBannerAsstes({
                bannerImageFg: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-fg.webp" : bannerHomeData.mobileFg,
                bannerBgImage: "#" + utmBgColor,
                bannerHeadlineImage: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-text.webp" : bannerHomeData.mainTextImgMobile
            })
            setDesktopBannerAssets({
                bannerImageFg: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-fg.webp" : bannerHomeData.desktopFg,
                bannerBgImage: "#" + utmBgColor,
                bannerHeadlineImage: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-text.webp" : bannerHomeData.mainTextImgDesktop
            })
        }
    }, [props.bannerHomeData])

    const [smsSent, setSmsSent] = useState<boolean>(false);
    const [smsSentMsg, setSmsSentMsg] = useState<string>(SENDING_MSG);
    const [numberError, setNumberError] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("-");

    useEffect(() => {
        setIsUtmPlayStore(getQueryStrings().utm === UTM_PLAYSTORE);
        const country = getCountryFromLocale(getCookie(USER_COUNTRY_CODE)).toUpperCase();
        countryCodes.forEach((item) => {
            if (item.code === country) setCountryCode(item.dial_code)
        })
    }, [])

    const countrySelectComponentProps: ICountrySelectComponentProps = {
        countryCode: countryCode,
        setCountryCode: setCountryCode,
        locale: props.locale,
        disableAppDownload
    }

// Function to handle number input
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const contry = getCountryFromLocale(props.locale);
        let countryConfig = numberValidationMapping[contry]
            ? numberValidationMapping[contry]
            : numberValidationMapping["DEFAULT"];
        if (event.target.value.length <= countryConfig.max) {
            setNumber(event.target.value);
        }
    };
    const validatePhoneNumber = (
        number: string,
        country: string
    ): string | undefined => {
        if (!number.length) {
            return mobileNumber.validMobileNumber;
        }
        let countryConfig = numberValidationMapping[country]
            ? numberValidationMapping[country]
            : numberValidationMapping["DEFAULT"];
        if (countryConfig) {
            if (number.length < countryConfig.min || number.length > countryConfig.max) {
                return mobileNumber.valid10digitMobileNumber;
            }
        }
    };
// function to handle submit number button
    const handleSubmit = (): void => {
        if (disableAppDownload) {
            return;
        }
        if (getOnlineStatus() === false) {
            setIsNetworkToastOpen(true);
            return;
        }
        let errorMessage: string | undefined = validatePhoneNumber(
            number,
            getCountryFromLocale(props.locale)
        );
        if (errorMessage) {
            setNumberError(errorMessage);
        } else {
            setSmsSentMsg(SENDING_MSG);
            setSmsSent(true);
            sendSMS(
                number,
                countryCode.substring(1),
                setSmsSentMsg,
                smsSentModalData.text1,
                smsVariablesTemplate
            );
            setNumberError("");
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.overflowY = "hidden";
            }
        }
    };

    const smsSentModalProps: ISmsSentModalProps = {
        smsSent: smsSent,
        smsSentMsg: smsSentMsg,
        setSmsSent: setSmsSent,
        number: number,
        smsSentModalData: smsSentModalData
    }

    return (
        bannerHomeData ?
            <>
                <div
                    className={multipleClassName("container-fluid", styles.mainContainer)}
                    style={typeof isMobileDevice === "undefined" || isMobileDevice
                        ? {backgroundColor: mobileBannerAssets?.bannerBgImage || bannerHomeData.bgColorMobile}
                        : {backgroundColor: desktopBannerAssets?.bannerBgImage || bannerHomeData.bgColorDesktop}}
                    id="banner"
                >
                    <CustomImageComponent
                        layout={'fill'}
                        objectFit={'cover'}
                        src={typeof isMobileDevice === "undefined" || isMobileDevice
                            ? mobileBannerAssets?.bannerBgImage || bannerHomeData.bgColorMobile
                            : desktopBannerAssets?.bannerBgImage || bannerHomeData.bgColorDesktop
                        }
                        alt={bannerHomeData.mobileImgAlt || "winzo main background image"}
                        priority={true}
                        className={isUtmPlayStore ? styles.bannerBackgroundImage : ""}
                    />
                    {isUtmPlayStore && <CustomImageComponent
                        layout={'fill'}
                        objectFit={'cover'}
                        src={typeof isMobileDevice === "undefined" || isMobileDevice
                            ? bannerMobilePlaystore || mobileBannerAssets?.bannerBgImage || bannerHomeData.bgColorMobile
                            : desktopBannerAssets?.bannerBgImage || bannerHomeData.bgColorDesktop
                        }
                        alt={bannerHomeData.mobileImgAlt || "winzo main background image"}
                        priority={true}
                        className={styles.bannerBackgroundImageUtmPlaystore}
                    />}
                    <div className={multipleClassName("container", styles.innerContainer)}>
                        <div className={multipleClassName("row", styles.styledRow)}>
                            <div
                                className={isDwarfBanner
                                    ? multipleClassName("col-sm-8", styles.mobileImageContainer_FB)
                                    : multipleClassName("col-sm-8", styles.mobileImageContainer)}>
                                <CustomImageComponent alt={bannerHomeData.mobileImgAlt}
                                                      src={
                                                          typeof isMobileDevice === "undefined" || isMobileDevice
                                                              ? mobileBannerAssets?.bannerImageFg || bannerHomeData.mobileFg
                                                              : desktopBannerAssets?.bannerImageFg || bannerHomeData.desktopFg
                                                      }
                                                      layout={'fill'}
                                                      objectPosition={"bottom"}
                                                      priority={true}
                                                      objectFit={'contain'}/>
                            </div>
                            <div className={isDwarfBanner
                                ? multipleClassName("col-md-4", styles.rightContainer_FB)
                                : multipleClassName("col-md-4", styles.rightContainer)}>
                                {!isUtmPlayStore && (
                                    bannerHomeData.mainTextImg ?
                                    <CustomCarouselComponent>
                                            {
                                                bannerHomeData.mainTextImg.map((image, index) => {
                                                    return (
                                                        <div key={"bannerHeadlineImage_" + index}
                                                             className={isDwarfBanner
                                                                 ? styles.bannerText_FB
                                                                 : styles.bannerText}>
                                                            <CustomImageComponent alt={"winzo-main-text"}
                                                                                  src={
                                                                                      typeof isMobileDevice === "undefined" || isMobileDevice
                                                                                          ? image.mobile
                                                                                          : image.desktop
                                                                                  }
                                                                                  layout={'fill'}
                                                                                  priority={true}
                                                                                  objectPosition={"bottom"}
                                                                                  objectFit={'contain'}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </CustomCarouselComponent>
                                        :
                                        <div className={isDwarfBanner
                                            ? styles.bannerText_FB
                                            : styles.bannerText}>
                                            <CustomImageComponent alt={"winzo-main-text"}
                                                                  src={
                                                                      typeof isMobileDevice === "undefined" || isMobileDevice
                                                                          ? mobileBannerAssets?.bannerHeadlineImage || bannerHomeData.mainTextImgMobile
                                                                          : desktopBannerAssets?.bannerHeadlineImage || bannerHomeData.mainTextImgDesktop
                                                                  }
                                                                  layout={'fill'}
                                                                  priority={true}
                                                                  objectPosition={"bottom"}
                                                                  objectFit={'contain'}/>
                                        </div>
                                )}
                                {!hideMobileNumberSection && <div className={styles.mobileInputContainer}>
                                    <div className={styles.inputContainer}>
                                        <CountrySelectComponent {...countrySelectComponentProps} />
                                        <div className={styles.inputBox}>
                                            <input
                                                type="text"
                                                maxLength={MAX_MOBILE_NUMBER_LENGTH}
                                                minLength={MIN_MOBILE_NUMBER_LENGTH}
                                                onInput={(e) => {
                                                    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                                                }}
                                                value={number}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (e.key === "Enter") {
                                                        handleSubmit()
                                                    }
                                                }
                                                }
                                                placeholder={bannerHomeData.text3}
                                                disabled={disableAppDownload}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className={styles.smsButtonContainer}>
                                        <button
                                            type="button"
                                            id="mainButton"
                                            onClick={() => handleSubmit()}
                                            disabled={disableAppDownload}
                                        >
                                            <p>
                                                {disableAppDownload ? "Coming Soon" : bannerHomeData.text4}
                                            </p>
                                        </button>
                                    </div>
                                    {consent&&(
                                        <div className={styles.consentContainer}>
                                            <p>
                                            {HTMLReactParser(consent)}
                                            </p>
                                        </div>
                                    )}
                                    <p className={styles.errorText}>&nbsp;{numberError}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <SmsSentModal {...smsSentModalProps} />
            </>
            :
            <></>
    )
}

export default BannerNewComponentV2;